body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media only screen and (max-width: 320px) {
  .emoji-mart.emoji-mart-light {
    width: "19rem";
  }
}
@media only screen and (max-width: 375px) {
  .emoji-mart.emoji-mart-light {
    width: "20rem";
  }
}
@media only screen and (max-width: 425px) {
  .emoji-mart.emoji-mart-light {
    width: "25rem";
  }
}
@media only screen and (max-width: 768px) {
  .emoji-mart.emoji-mart-light {
    width: "30rem";
  }
}
@media only screen and (max-width: 1024px) {
  .emoji-mart.emoji-mart-light {
    width: "50rem";
  }
}
@media only screen and (max-width: 1440px) {
  .emoji-mart.emoji-mart-light {
    width: "65rem";
  }
}
body[dir="rtl"] {
  unicode-bidi: bidi-override;
  direction: rtl;
  text-align: right;
}

body[dir="rtl"] .appbar .nav,
body[dir="rtl"] .navbar .nav {
  padding: 0;
}

@media (min-width: 1200px) {
  body[dir="rtl"] .sidebar {
    border-right: initial;
    border-left: 1px solid #e5e9f2;
  }
}

body[dir="rtl"] .contacts-list li.contacts-item {
  text-align: right;
}

body[dir="rtl"] .contacts-content {
  padding-right: 0.875rem;
  padding-left: 0;
}

body[dir="rtl"] .contacts-content .contacts-info .chat-name,
body[dir="rtl"] .contacts-content .contacts-texts p {
  margin-left: auto;
  margin-right: 0;
}

body[dir="rtl"] .list-group {
  padding: 0;
}

@media (min-width: 1200px) {
  body[dir="rtl"] .chat-info {
    border-left: 0;
    border-right: 1px solid #e5e9f2;
  }
}

@media screen and (max-width: 1300px) and (min-width: 1200px) {
  body[dir="rtl"] .chat-info.chat-info-visible {
    border-left: 0;
    border-right: 1px solid #e5e9f2;
  }
}

body[dir="rtl"] .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
  border-right: 1px solid #e5e9f2 !important;
  border-left: 0;
  padding-right: 0.75rem !important;
  padding-left: 0;
}

body[dir="rtl"] .input-group .input-group-append .input-group-text {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0;
  border-left: 1px solid #e5e9f2 !important;
  border-right: 0;
}

body[dir="rtl"] .custom-file .custom-file-label:after {
  left: 0;
  right: auto;
  border-radius: 0.25rem 0 0 0.25rem;
  border-left: 0;
  border-right: initial;
}

body[dir="rtl"] .custom-radio .form-check-label {
  padding-right: 1rem;
}

body[dir="rtl"] .modal-dialog .modal-header .close {
  margin: -1rem auto -1rem -1rem;
}

body[dir="rtl"] .message.self {
  text-align: left;
}

@media (min-width: 1200px) {
  body[dir="rtl"] .appbar .appnavbar-content.appnavbar-content-visible {
    left: 3.75rem;
    right: auto;
  }
}

body[dir="rtl"] .appbar .todo-item .custom-checkbox .custom-control-label:after,
body[dir="rtl"]
  .appbar
  .todo-item
  .custom-checkbox
  .custom-control-label:before {
  left: -0.5rem;
}

body[dir="rtl"] .mr-1 {
  margin-right: 0 !important;
  margin-left: 0.375rem !important;
}

body[dir="rtl"] .mr-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

body[dir="rtl"] .mr-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}

body[dir="rtl"] .mr-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}

body[dir="rtl"] .ml-1 {
  margin-right: 0.375rem !important;
  margin-left: 0 !important;
}

body[dir="rtl"] .ml-2 {
  margin-right: 0.5rem !important;
  margin-left: 0 !important;
}

body[dir="rtl"] .ml-auto {
  margin-left: 0 !important;
  margin-right: auto !important;
}

body[dir="rtl"] .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

body[dir="rtl"] .mr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

body[dir="rtl"] .mfp-counter {
  direction: ltr;
}

body[dir="rtl"] .chat-header [data-close] {
  transform: rotate(180deg);
}

body[dir="rtl"] .chat-body .chat-footer .send-icon {
  left: 0.625rem;
  right: auto;
}

body[dir="rtl"] .chat-body .chat-footer .attachment {
  right: 0.75rem;
  left: auto;
}

body[dir="rtl"] .chat-body .chat-footer .emojionearea-editor {
  padding: 1.5rem 2.5rem 1.5rem 6.5rem;
}

body[dir="rtl"] .chat-body .chat-footer .emojionearea-button {
  left: 70px;
  right: auto;
}
