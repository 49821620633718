.borad-width {
  // min-width: 400px;
  width: 400px;
}

@media (max-width: 1024px) {
  .borad-width {
    //min-width: 300px;
    width: 300px;
  }
}
